//
// Core: Preloader
//

.preloader {
  display: flex;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
  height: 100vh;
  width: 100%;
  // transition: height 200ms linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: $zindex-preloader;
}

.dark-mode {
  .preloader {
    background-color: $dark-main-bg;
    color: $white;
  }
}

//
