//
// Mixins: Toasts
//

// Toast Variant
@mixin toast-variant($name, $color) {
  &.bg-#{$name} {
    // background-color: rgba($color, .9) !important;
    background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
    );
    border-left: 5px solid #e9ecef;
    border-left-color: rgba($color, .85);
    // @if (color-yiq($color) == $yiq-text-light) {

    //   .close {
    //     // color: color-yiq($color);
    //     text-shadow: 0 1px 0 $black;
    //   }
    // }

    // .toast-header {
    //   background-color: rgba($color, .85);
    //   color: color-yiq($color);
    // }

  }
}

